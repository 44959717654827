import {
  LightShopifyLogo,
  DarkShopifyLogo,
} from '@/components/brochureV2/section/Header/svg/ShopifyLogo';
import PartnersLogo from '@/pages/shopify.com/($locale)/partners/directory/components/Header/components/PartnersLogo/PartnersLogo';
import useGlobalNav from '@/hooks/navigation/global';
import {partnersPath} from '@/hooks/navigation/utils';
import {useSiteData} from '@/components/shared/Page/SiteData';
import {useTranslations} from '@/hooks/useTranslations';

interface ShopifyLogoProps {
  darkBackground?: boolean;
}

export default function ShopifyLogo({darkBackground}: ShopifyLogoProps) {
  const {homeUrl} = useGlobalNav();
  return (
    <div className="nav:w-[125px] mr-12 w-[98px] shrink-0">
      <a href={homeUrl} data-component-name="logo-home">
        {darkBackground ? <DarkShopifyLogo /> : <LightShopifyLogo />}
      </a>
    </div>
  );
}

export function ShopifyLogoPartners() {
  const {site} = useSiteData();
  const {t} = useTranslations();
  return (
    <div className="w-[150px] sm:w-[217px] mt-0 sm:mr-12 shrink-0">
      <a
        href={partnersPath(site)}
        data-component-name="partners-logo"
        aria-label={t('global:ariaLabels.shopifyPartnersLogo')}
      >
        <PartnersLogo className="max-w-full" />
      </a>
    </div>
  );
}
